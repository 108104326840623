import founder from './images/founder.png';

const FoundingStory = () => {
    return (
        <div className="w-full lg:px-12 px-6 sm:py-20 py-12 grid sm:grid-cols-2 grid-cols-1 justify-center text-sm">
            <div className="flex items-center justify-center">
                <img src={founder} alt='Founder' className='shadow-lg rounded-lg pointer-events-none'></img>
            </div>
            <div className='flex flex-col gap-4 font-normal text-base sm:w-5/6 justify-center mt-12 sm:mt-0'>
                <h2 className='text-3xl font-semibold'>Our founding story</h2>
                <div>Several years ago, our founder, immersed herself in the bustling world of technology, fueled by a passion to revolutionize digital landscapes.</div>
                <div>Establishing Jibu Labs, she embarked on a journey to redefine possibilities, specializing in bespoke websites, mobile apps, software ERPs, and AI products.</div>
                <div>Inspired by her unwavering commitment to excellence, clients flocked to her doorstep, drawn by the promise of transformative digital solutions.</div>
                <div>With each project undertaken, the team pushed boundaries, leaving an indelible mark on the digital frontier and proving the extraordinary power of perseverance and innovation.</div>
            </div>
        </div>
    );
}

export default FoundingStory;